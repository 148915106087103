import React from 'react';
import { z } from 'zod';
import { Collectible, CollectibleType } from '@sportscardinvestor/schemas';
import { Controller, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { Tooltip } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleInfo } from '@fortawesome/pro-light-svg-icons';
import PriceField from 'sci-ui-components/forms/PriceField/PriceField';
import FieldLabelBox from 'sci-ui-components/forms/FieldLabelBox/FieldLabelBox';
import { useOnValueChange } from '@/hooks/useOnValueChange';
import { EditForm, EditFormButtons, FormSeparator } from '@/sci-ui-components/forms/EditForm';
import { CollectibleItem, CustomPrice } from '@/features/collectibles/CollectibleItem';
import { collectibleNames } from '@/sci-ui-components/collectibles/constants';

const formSchema = z.object({
  currentPriceInDisplayCurency: z.number().nullable().optional(),
});

export type EditFeaturedCollectibleCustomValueFormInitialValues = z.input<typeof formSchema>;
export type EditFeaturedCollectibleCustomValueFormPayload = z.output<typeof formSchema>;
export type OnEditFeaturedCollectibleCustomValueFormSubmitFn = (
  params: EditFeaturedCollectibleCustomValueFormPayload
) => void;

export function EditFeaturedCollectibleCustomValueForm({
  collectibleType,
  initialValues,
  collectible,
  customPrice,
  onCancel,
  onSubmit,
  formId = 'EditFeaturedCollectibleCustomValueForm',
  isLoading,
  isLoadingCollectible,
}: {
  initialValues: EditFeaturedCollectibleCustomValueFormInitialValues | null;
  collectibleType: CollectibleType;
  collectible: Collectible | null;
  customPrice: CustomPrice | null;
  onSubmit: OnEditFeaturedCollectibleCustomValueFormSubmitFn;
  onCancel: () => void;
  formId?: string;
  isLoading: boolean;
  isLoadingCollectible: boolean;
}) {
  const form = useForm<
    EditFeaturedCollectibleCustomValueFormInitialValues,
    unknown,
    EditFeaturedCollectibleCustomValueFormPayload
  >({
    resolver: zodResolver(formSchema),
    defaultValues: initialValues ?? undefined,
    mode: 'onSubmit',
  });
  useOnValueChange(initialValues, () => form.reset(initialValues ?? undefined));

  const handleSubmit = form.handleSubmit((payload: EditFeaturedCollectibleCustomValueFormPayload) => onSubmit(payload));
  return (
    <>
      <FormSeparator noTopMargin />
      <CollectibleItem
        collectible={collectible}
        isLoading={isLoadingCollectible}
        collectibleType={collectibleType}
        size="lg"
        customPrice={customPrice}
        className="mb-4 w-full"
      />
      <EditForm onSubmit={handleSubmit} id={formId} className="flex flex-col gap-6 w-[612px] md:w-[960px] max-w-full">
        <Controller
          name="currentPriceInDisplayCurency"
          control={form.control}
          render={({ field, fieldState }) => (
            <FieldLabelBox
              className="w-full"
              label={
                <span className="flex items-center gap-1">
                  <span>New Value</span>
                  <Tooltip
                    title={`We update the values of all featured ${collectibleNames[collectibleType].shortPlural} daily. For ${collectibleNames[collectibleType].shortPlural} without a recorded sale in online marketplaces, you can specify your own value.`}
                  >
                    <FontAwesomeIcon icon={faCircleInfo} />
                  </Tooltip>
                </span>
              }
              fieldId="currentPriceInDisplayCurency"
              error={fieldState.error?.message}
              variant="neater"
            >
              <PriceField {...field} id="currentPriceInDisplayCurency" min={0} />
            </FieldLabelBox>
          )}
        />
      </EditForm>
      <EditFormButtons
        className="col-span-3 md:col-span-1 md:col-start-3 md:row-start-4"
        onCancel={onCancel}
        isLoading={isLoading}
        formId={formId}
      />
    </>
  );
}
