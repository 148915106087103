import { ReactNode, useMemo } from 'react';
import { z } from 'zod';
import clsx from 'clsx';
import { useForm, Controller } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { getIsCollectibleGraded } from '@sportscardinvestor/collectible-helpers';
import { Collectible, CustomCollectible, CollectionItem, CollectibleType } from '@sportscardinvestor/schemas';
import CollectionCategoryFilter from '../CollectionCategoryFilter';
import { CollectibleItem } from '@/features/collectibles/CollectibleItem';
import { useOnValueChange } from '@/hooks/useOnValueChange';
import NumberField from '@/sci-ui-components/forms/NumberField/NumberField';
import FieldLabelBox from '@/sci-ui-components/forms/FieldLabelBox/FieldLabelBox';
import { collectibleNames } from '@/sci-ui-components/collectibles/constants';
import PriceField from '@/sci-ui-components/forms/PriceField/PriceField';
import { EditForm, EditFormButtons, FormSeparator } from '@/sci-ui-components/forms/EditForm';
import { ConvertPriceFn } from '@/hooks/useCurrencyFormatter';
import DateSelector from '@/sci-ui-components/forms/DateSelector/DateSelector';
import TextField from '@/sci-ui-components/forms/TextField/TextField';
import { formatISODate } from '@/sci-ui-components/utils/date';
import useToday from '@/sci-ui-components/hooks/useToday';

const formSchema = z.object({
  purchaseDate: z.string({
    required_error: 'Please enter purchase date',
  }),
  quantity: z
    .number({
      required_error: 'Please enter quantity',
    })
    .min(1, 'Must be at least 1'),
  purchasePricePerItemInDisplayCurrency: z.number().nullable().optional(),
  purchasePriceTotalInDisplayCurrency: z.number().nullable().optional(),
  gradingPricePerItemInDisplayCurrency: z.number().min(0).nullable().optional(),
  gradingPriceTotalInDisplayCurrency: z.number().min(0).nullable().optional(),
  categoryId: z.number().nullable().default(null),
  notes: z.string().nullable().default(null),
});

export type EditCollectionItemPurchaseDetailsInitialValues = z.input<typeof formSchema>;
export type EditCollectionItemPurchaseDetailsFormPayload = z.output<typeof formSchema>;

export type OnEditCollectionItemPurchaseDetailsFormSubmitFn = (
  payload: EditCollectionItemPurchaseDetailsFormPayload
) => void;

export function EditCollectionItemPurchaseDetailsForm({
  onSubmit,
  onCancel,
  formId = 'EditCollectionItemPurchaseDetailsForm',
  initialValues,
  collectible,
  isLoading,
  cancelText,
  submitText,
  showSubHeader,
  collectibleType,
  isLoadingCollectible,
}: {
  onSubmit: OnEditCollectionItemPurchaseDetailsFormSubmitFn;
  onCancel: () => void;
  cancelText?: string;
  submitText?: string;
  formId?: string;
  initialValues: EditCollectionItemPurchaseDetailsInitialValues | null;
  collectible: Collectible | CustomCollectible | null;
  isLoadingCollectible?: boolean;
  collectibleType: CollectibleType;
  isLoading?: boolean;
  showSubHeader?: boolean;
}) {
  const today = useToday();
  const defaultValues = useMemo<EditCollectionItemPurchaseDetailsInitialValues>(
    () =>
      initialValues
        ? {
            ...initialValues,
            // NOTE: remove 0 values to show placeholders in fields by default
            purchasePricePerItemInDisplayCurrency: initialValues.purchasePricePerItemInDisplayCurrency || undefined,
            purchasePriceTotalInDisplayCurrency: initialValues.purchasePriceTotalInDisplayCurrency || undefined,
            gradingPricePerItemInDisplayCurrency: initialValues.gradingPricePerItemInDisplayCurrency || undefined,
            gradingPriceTotalInDisplayCurrency: initialValues.gradingPriceTotalInDisplayCurrency || undefined,
          }
        : {
            purchaseDate: formatISODate(today),
            quantity: 1,
          },
    [initialValues, today]
  );

  const form = useForm<
    EditCollectionItemPurchaseDetailsInitialValues,
    unknown,
    EditCollectionItemPurchaseDetailsFormPayload
  >({
    resolver: zodResolver(formSchema),
    defaultValues,
    mode: 'onSubmit',
  });
  useOnValueChange(initialValues, () => form.reset(defaultValues));

  const handleSubmit = form.handleSubmit((payload: EditCollectionItemPurchaseDetailsFormPayload) => {
    let {
      purchasePricePerItemInDisplayCurrency,
      purchasePriceTotalInDisplayCurrency,
      gradingPricePerItemInDisplayCurrency,
      gradingPriceTotalInDisplayCurrency,
      quantity,
    } = payload;
    if (purchasePricePerItemInDisplayCurrency) {
      purchasePriceTotalInDisplayCurrency = quantity * purchasePricePerItemInDisplayCurrency;
    } else if (purchasePriceTotalInDisplayCurrency) {
      purchasePricePerItemInDisplayCurrency = purchasePriceTotalInDisplayCurrency / quantity;
    } else {
      purchasePricePerItemInDisplayCurrency = 0;
      purchasePriceTotalInDisplayCurrency = 0;
    }
    if (gradingPricePerItemInDisplayCurrency) {
      gradingPriceTotalInDisplayCurrency = quantity * gradingPricePerItemInDisplayCurrency;
    } else if (gradingPriceTotalInDisplayCurrency) {
      gradingPricePerItemInDisplayCurrency = gradingPriceTotalInDisplayCurrency / quantity;
    } else {
      gradingPricePerItemInDisplayCurrency = 0;
      gradingPriceTotalInDisplayCurrency = 0;
    }
    onSubmit({
      ...payload,
      purchasePricePerItemInDisplayCurrency,
      purchasePriceTotalInDisplayCurrency,
      gradingPricePerItemInDisplayCurrency,
      gradingPriceTotalInDisplayCurrency,
    });
  });

  const isGradedCollectible = collectible ? getIsCollectibleGraded(collectible) : false;
  const collectibleName = collectibleNames[collectibleType].shortSingular;

  return (
    <>
      <FormSeparator noTopMargin />
      <CollectibleItem
        collectibleType={collectibleType}
        collectible={collectible}
        className="mb-4 w-full"
        size="lg"
        isLoading={isLoadingCollectible}
      />
      <EditForm
        onSubmit={handleSubmit}
        id={formId}
        className="flex flex-col items-stretch w-[612px] lg:w-[1024px] max-w-full"
      >
        {!!showSubHeader && <h2 className="text-lg font-semibold mb-4">Purchase details</h2>}
        <section className="flex flex-col items-stretch gap-4 lg:flex-row lg:items-start md:gap-6">
          <div className="grow shrink flex flex-col items-stretch gap-4 md:gap-6">
            <FieldsPairContainer>
              <Controller
                name="purchaseDate"
                control={form.control}
                render={({ field, fieldState }) => (
                  <FieldLabelBox
                    className="col-span-3 md:col-span-1"
                    label="Purchase Date"
                    fieldId="purchaseDate"
                    error={fieldState.error?.message}
                    variant="neater"
                  >
                    <DateSelector {...field} id="purchaseDate" />
                  </FieldLabelBox>
                )}
              />
              <span />
              <Controller
                name="quantity"
                control={form.control}
                render={({ field, fieldState }) => (
                  <FieldLabelBox
                    className="col-span-3 md:col-span-1"
                    label="Quantity"
                    fieldId="quantity"
                    error={fieldState.error?.message}
                    variant="neater"
                  >
                    <NumberField
                      {...field}
                      onChange={(value, name) => {
                        field.onChange({ target: { value, name } });
                        const values = form.getValues();
                        if (!value) {
                          return;
                        }
                        if (values.purchasePricePerItemInDisplayCurrency) {
                          form.setValue(
                            'purchasePriceTotalInDisplayCurrency',
                            values.purchasePricePerItemInDisplayCurrency * value
                          );
                        } else if (values.purchasePriceTotalInDisplayCurrency) {
                          form.setValue(
                            'purchasePricePerItemInDisplayCurrency',
                            values.purchasePriceTotalInDisplayCurrency / value
                          );
                        }

                        if (values.gradingPricePerItemInDisplayCurrency) {
                          form.setValue(
                            'gradingPriceTotalInDisplayCurrency',
                            values.gradingPricePerItemInDisplayCurrency * value
                          );
                        } else if (values.gradingPriceTotalInDisplayCurrency) {
                          form.setValue(
                            'gradingPricePerItemInDisplayCurrency',
                            values.gradingPriceTotalInDisplayCurrency / value
                          );
                        }
                      }}
                      id="quantity"
                      min={1}
                    />
                  </FieldLabelBox>
                )}
              />
            </FieldsPairContainer>
            <FieldsPairContainer>
              <Controller
                name="purchasePricePerItemInDisplayCurrency"
                control={form.control}
                render={({ field, fieldState }) => (
                  <FieldLabelBox
                    className="col-span-3 md:col-span-1"
                    label={
                      <>
                        Purchase Price<span className="md:hidden">&nbsp;per {collectibleName}</span>
                      </>
                    }
                    fieldId="purchasePricePerItemInDisplayCurrency"
                    error={fieldState.error?.message}
                    optional
                    variant="neater"
                  >
                    <PriceField
                      {...field}
                      onChange={(value, name) => {
                        field.onChange({ target: { value, name } });
                        const values = form.getValues();
                        if (values.quantity) {
                          form.setValue(
                            'purchasePriceTotalInDisplayCurrency',
                            typeof value === 'number' ? values.quantity * value : null
                          );
                        }
                      }}
                      id="purchasePricePerItemInDisplayCurrency"
                      placeholder={`Per ${collectibleName}`}
                      min={0}
                    />
                  </FieldLabelBox>
                )}
              />
              <OrText />
              <Controller
                name="purchasePriceTotalInDisplayCurrency"
                control={form.control}
                render={({ field, fieldState }) => (
                  <FieldLabelBox
                    className="col-span-3 md:col-span-1"
                    label={
                      <>
                        Total<span className="md:hidden">&nbsp;Purchase Price</span>
                      </>
                    }
                    fieldId="purchasePriceTotalInDisplayCurrency"
                    error={fieldState.error?.message}
                    optional
                    variant="neater"
                    labelClassName="md:invisible"
                  >
                    <PriceField
                      {...field}
                      onChange={(value, name) => {
                        field.onChange({ target: { value, name } });
                        const values = form.getValues();
                        if (values.quantity) {
                          form.setValue(
                            'purchasePricePerItemInDisplayCurrency',
                            typeof value === 'number' ? value / values.quantity : null
                          );
                        }
                      }}
                      id="purchasePriceTotalInDisplayCurrency"
                      placeholder="Total"
                      min={0}
                    />
                  </FieldLabelBox>
                )}
              />
            </FieldsPairContainer>
            {isGradedCollectible && (
              <FieldsPairContainer>
                <Controller
                  name="gradingPricePerItemInDisplayCurrency"
                  control={form.control}
                  render={({ field, fieldState }) => (
                    <FieldLabelBox
                      className="col-span-3 md:col-span-1"
                      label={
                        <>
                          Grading Cost<span className="md:hidden">&nbsp;per {collectibleName}</span>
                        </>
                      }
                      fieldId="gradingPricePerItemInDisplayCurrency"
                      error={fieldState.error?.message}
                      optional
                      variant="neater"
                    >
                      <PriceField
                        {...field}
                        onChange={(value, name) => {
                          field.onChange({ target: { value, name } });
                          const values = form.getValues();
                          if (values.quantity) {
                            form.setValue(
                              'gradingPriceTotalInDisplayCurrency',
                              typeof value === 'number' ? values.quantity * value : null
                            );
                          }
                        }}
                        id="gradingPricePerItemInDisplayCurrency"
                        placeholder={`Per ${collectibleName}`}
                      />
                    </FieldLabelBox>
                  )}
                />
                <OrText />
                <Controller
                  name="gradingPriceTotalInDisplayCurrency"
                  control={form.control}
                  render={({ field, fieldState }) => (
                    <FieldLabelBox
                      className="col-span-3 md:col-span-1"
                      label={
                        <>
                          Total<span className="md:hidden">&nbsp;Grading Cost</span>
                        </>
                      }
                      fieldId="gradingPriceTotalInDisplayCurrency"
                      error={fieldState.error?.message}
                      optional
                      variant="neater"
                      labelClassName="md:invisible"
                    >
                      <PriceField
                        {...field}
                        onChange={(value, name) => {
                          field.onChange({ target: { value, name } });
                          const values = form.getValues();
                          if (values.quantity) {
                            form.setValue(
                              'gradingPricePerItemInDisplayCurrency',
                              typeof value === 'number' ? value / values.quantity : null
                            );
                          }
                        }}
                        id="gradingPriceTotalInDisplayCurrency"
                        placeholder="Total"
                      />
                    </FieldLabelBox>
                  )}
                />
              </FieldsPairContainer>
            )}
          </div>
          <div className="flex flex-col items-stretch gap-4 md:gap-6">
            <Controller
              name="categoryId"
              control={form.control}
              render={({ field, fieldState }) => (
                <CollectionCategoryFilter
                  label="Category"
                  id="categoryId"
                  collectibleType={collectibleType}
                  onChange={(value) => field.onChange({ target: { value, name: field.name } })}
                  selectedCategoryId={field.value ?? null}
                  noValueLabel="None"
                  autoSelectAdded
                  error={fieldState.error?.message}
                  variant="neater"
                />
              )}
            />
            <Controller
              name="notes"
              control={form.control}
              render={({ field, fieldState }) => (
                <FieldLabelBox
                  label="Notes"
                  fieldId="notes"
                  className="min-w-72"
                  error={fieldState.error?.message}
                  variant="neater"
                >
                  <TextField
                    {...field}
                    id="notes"
                    isMultiLine
                    rows={3}
                    placeholder="Do not use commas if you plan to Download to CSV"
                  />
                </FieldLabelBox>
              )}
            />
          </div>
        </section>
      </EditForm>
      <EditFormButtons
        onCancel={onCancel}
        isLoading={isLoading}
        formId={formId}
        cancelText={cancelText}
        submitText={submitText}
      />
    </>
  );
}

function FieldsPairContainer({ children, className }: { children: ReactNode; className?: string }) {
  return <section className={clsx('grid grid-cols-[1fr_0px_1fr] gap-4 md:gap-6', className)}>{children}</section>;
}

function OrText({ className }: { className?: string }) {
  return (
    <div
      className={clsx(
        'col-span-3 md:col-span-1 w-full overflow-visible hidden md:flex items-center justify-center',
        className
      )}
    >
      <FieldLabelBox className="-left-1/2" variant="neater" label="&nbsp;" labelClassName="invisible">
        <p className="uppercase font-semibold text-lg m-0">OR</p>
      </FieldLabelBox>
    </div>
  );
}

export function getEditCollectionItemPurchaseDetailsFormInitialValues({
  collectionItem,
  convertPriceFromUSD,
}: {
  convertPriceFromUSD: ConvertPriceFn;
  collectionItem: CollectionItem | null | undefined;
}): EditCollectionItemPurchaseDetailsInitialValues {
  const quantity = collectionItem?.quantity ?? 1;
  const purchasePricePerItemInDisplayCurrency =
    convertPriceFromUSD({ value: collectionItem?.purchasePricePerItem }) ?? 0;
  const gradingPricePerItemInDisplayCurrency = convertPriceFromUSD({ value: collectionItem?.gradingPricePerItem }) ?? 0;
  return {
    quantity,
    purchasePricePerItemInDisplayCurrency,
    purchasePriceTotalInDisplayCurrency: quantity * purchasePricePerItemInDisplayCurrency,
    gradingPricePerItemInDisplayCurrency,
    gradingPriceTotalInDisplayCurrency: quantity * gradingPricePerItemInDisplayCurrency,
    purchaseDate: collectionItem?.datePurchased ?? formatISODate(new Date()),
    categoryId: collectionItem?.collectionCategories?.[0]?.id ?? null,
    notes: collectionItem?.note ?? '',
  };
}
