import { KeyboardEventHandler, MouseEventHandler, ReactNode, useRef } from 'react';
import clsx from 'clsx';

export interface ClickableWrapperProps {
  className?: string;
  onClick: () => void;
  children: ReactNode;
  disabled?: boolean;
}

export function ClickableWrapper({ onClick, className: classNameParam, disabled, ...rest }: ClickableWrapperProps) {
  const ref = useRef<HTMLDivElement>(null);
  const handleClick: MouseEventHandler<HTMLDivElement> = (e) => {
    let shouldHandleClick = true;
    if (ref.current) {
      let currentNode: HTMLElement | null | undefined = e.target as HTMLElement;
      while (currentNode) {
        if (currentNode === ref.current || currentNode === document.body) {
          currentNode = null;
          continue;
        }
        const tag = currentNode?.tagName?.toLowerCase();
        const role = currentNode?.role?.toLowerCase();
        if (tag === 'a' || tag === 'input' || tag === 'button' || role === 'button') {
          currentNode = null;
          shouldHandleClick = false;
          continue;
        }
        currentNode = currentNode?.parentElement;
      }
    }
    if (shouldHandleClick) {
      onClick();
    }
  };
  const onKeyDown: KeyboardEventHandler<HTMLDivElement> = (e) => {
    if (e.key === 'Enter' || e.key === ' ') {
      e.preventDefault();
      onClick();
    }
  };
  const className = clsx(
    'twp w-full h-full',
    !disabled && 'cursor-pointer hover:bg-lightBg-accentPrimary dark:hover:bg-darkBg-accentPrimary',
    classNameParam
  );
  return (
    <div
      {...rest}
      ref={ref}
      className={className}
      onClick={handleClick}
      onKeyDown={onKeyDown}
      tabIndex={disabled ? -1 : 0}
      role="button"
    />
  );
}
