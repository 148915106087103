import { isValidMarketplace, normalizeListingType } from '@sportscardinvestor/marketplace-helpers';
import { parseSalePlatform, salePlatformsInfo } from '@sportscardinvestor/schemas';
import { CollectibleType } from '../../../../sci-ui-components/types/collectibleType';
import {
  CompletedSalesListingItem,
  CompletedSalesListingItemBase,
  CompletedSalesListingItemSportsCard,
  CompletedSalesListingItemSealedWax,
} from '../../../../sci-ui-components/types/sales';
import {
  ApiCompletedSalesListingItem,
  ApiCompletedSalesListingItemSportsCards,
  ApiCompletedSalesListingItemSealedWax,
} from '../../types';
import { marketplaceHelpers } from 'services/marketplaceHelpers';

export default function completedSalesListingItemFromApi(
  apiItem: ApiCompletedSalesListingItem,
  collectibleType: CollectibleType
): CompletedSalesListingItem {
  const platformId = parseSalePlatform({
    platformName: apiItem.source,
    sellerName: apiItem.seller_name,
  });
  const sellerInfo = salePlatformsInfo[platformId];
  const base: Omit<CompletedSalesListingItemBase, 'collectibleId' | 'jobMasterCollectibleId' | 'sellerInfo'> = {
    auctionBidsCount: apiItem.number_of_bids ? Number(apiItem.number_of_bids) : null,
    auctionId: apiItem.auction_id,
    auctionPaymentStatus: apiItem.auction_payment_status,
    auctionUrl: marketplaceHelpers.makeDirectItemUrl({
      url: apiItem.auction_url,
      marketplace: isValidMarketplace(apiItem.source) ? apiItem.source : 'ebay_jobs',
      listingType: normalizeListingType(apiItem.listing_type),
    }),
    autoExcluded: apiItem.auto_excluded,
    autoExcludedAverage: apiItem.auto_excluded_average,
    autoExcludedRange: apiItem.auto_excluded_range,
    autoExcludedTier: apiItem.auto_excluded_tier,
    autoExcludedVariance: apiItem.auto_excluded_variance,
    bestOfferEnabled: apiItem.best_offer_enabled,
    buyerAccountCreatedAt: apiItem.buyer_account_created_at,
    buyerFeedbackRating: apiItem.buyer_feedback_rating ? Number(apiItem.buyer_feedback_rating) : null,
    buyItNowAvailable: apiItem.buy_it_now_available,
    cardSetId: apiItem.card_set_id,
    collectibleType,
    createdAt: apiItem.created_at,
    endDateTime: apiItem.end_time,
    externalSaleId: apiItem.external_sale_id,
    imageUrl: apiItem.image_url,
    isDeleted: apiItem.deleted,
    isFlaggedByUser: apiItem.flagged_by_user,
    isFromSeed: apiItem.is_from_seed,
    isIncluded: apiItem.included,
    lastAuctionPaymentCheck: apiItem.last_auction_payment_check,
    listingId: apiItem.id,
    listingStatus: apiItem.status,
    listingType: apiItem.listing_type,
    location: apiItem.location,
    offerPrice: apiItem.offer_price ? Number(apiItem.offer_price) : null,
    offerPriceChecked: apiItem.offer_price_checked,
    offerPriceCheckedCount: apiItem.offer_price_checked_count,
    offerPriceMessage: apiItem.offer_price_message,
    sellerEbayGoodStanding: apiItem.ebay_good_standing,
    sellerFeedbackRating: apiItem.feedback_rating ? Number(apiItem.feedback_rating) : null,
    sellingPrice: apiItem.selling_price,
    startDateTime: apiItem.start_time,
    title: apiItem.title,
    updatedAt: apiItem.updated_at,
    soldPrice: Number(apiItem.sold_price),
    platformId,
  };

  switch (collectibleType) {
    case 'sports-card': {
      const sportsCardApiItem = apiItem as ApiCompletedSalesListingItemSportsCards;
      const result: CompletedSalesListingItemSportsCard = {
        ...base,
        collectibleId: sportsCardApiItem.card_id,
        jobMasterCollectibleId: sportsCardApiItem.job_master_card_id,
        sellerInfo,
        autoExcludedImageSimilarity: sportsCardApiItem.auto_excluded_image_similarity,
        jobMasterCardSetId: sportsCardApiItem.job_master_set_id,
      };
      return result;
    }
    case 'sealed-wax-card': {
      const sportsCardApiItem = apiItem as ApiCompletedSalesListingItemSealedWax;
      const result: CompletedSalesListingItemSealedWax = {
        ...base,
        collectibleId: sportsCardApiItem.sealed_wax_id,
        jobMasterCollectibleId: sportsCardApiItem.job_wax_master_set_id,
        sellerInfo,
      };
      return result;
    }
  }
}
