import { useCallback, useMemo } from 'react';
import { useInfiniteQuery, UseInfiniteQueryOptions, useQuery, UseQueryOptions } from 'react-query';
import { SalePlatform } from '@sportscardinvestor/schemas';
import {
  getForSaleListings,
  GetForSaleListingsParams,
  GetForSaleListingsResponse,
} from '../../services/sciApi/sales/index';
import { ApiError } from '../../utils/api';
import useAuth from '../auth/useAuth';
import useFilteredData, { Filter } from '../../hooks/useFilteredData';
import {
  ForSaleListingItem,
  ForSaleListingItemSportsCard,
  ForSaleListingItemSealedWax,
} from '../../sci-ui-components/types/sales';
import { ForSaleItemType } from '../../sci-ui-components/types/sales';
import {
  CollectibleType,
  isSealedWaxCardCollectibleType,
  isSportsCardCollectibleType,
} from 'sci-ui-components/types/collectibleType';

export interface FilterParams {
  searchText?: string | null;
  marketplaces?: SalePlatform[];
  acceptsOffers?: boolean;
  playerName?: string | null;
  setName?: string;
  setYear?: number;
  isPublic?: boolean | undefined;
}

export const keyPrefix = 'for-sale';
export type InfiniteQueryParams = Omit<GetForSaleListingsParams, 'offset' | 'listingTypes'> &
  FilterParams & {
    forSaleItemType: ForSaleItemType;
  };
type InfiniteKey = [typeof keyPrefix, Omit<GetForSaleListingsParams, 'offset'>];
export type PaginatedQueryParams = GetForSaleListingsParams & FilterParams;
type PaginatedKey = [typeof keyPrefix, GetForSaleListingsParams];

export function useForSaleListingsInfinite(
  params: InfiniteQueryParams,
  options: UseInfiniteQueryOptions<
    GetForSaleListingsResponse,
    ApiError,
    GetForSaleListingsResponse,
    GetForSaleListingsResponse,
    InfiniteKey
  > = {}
) {
  const { collectibleIds, limit, collectibleType, sortBy, sortDirection, forSaleItemType, advancedFilters } = params;
  const listingTypes = forSaleItemType === 'Auctions' ? ['Auction'] : ['Store', 'AuctionWithBin'];
  const { isLoggedIn } = useAuth();
  const queryResult = useInfiniteQuery(
    [
      keyPrefix,
      {
        limit,
        collectibleIds,
        sortBy,
        sortDirection,
        collectibleType,
        listingTypes,
        advancedFilters,
      },
    ],
    async ({ pageParam: offset = 0, signal }) =>
      getForSaleListings(
        {
          limit,
          collectibleIds,
          sortBy,
          sortDirection,
          collectibleType,
          offset,
          listingTypes,
          advancedFilters,
        },
        signal
      ),
    {
      staleTime: 1000 * 60 * 60 * 1, // 1 hour
      ...options,
      enabled: !!isLoggedIn && !!collectibleIds?.length && (options?.enabled ?? true),
      getNextPageParam: (lastPage, allPages) => {
        if (!lastPage.totalCount || !limit) {
          return null;
        }
        const totalItems = allPages.reduce((acc, { items }) => acc + items.length, 0);
        if (lastPage.totalCount <= totalItems) {
          return null;
        }
        return totalItems;
      },
    }
  );

  const data = useMemo(
    () => queryResult.data?.pages?.reduce<ForSaleListingItem[]>((acc, { items }) => acc.concat(items), []) ?? [],
    [queryResult.data]
  );

  const filteredData = useFilteredListings(data, collectibleType, params);

  return {
    ...queryResult,
    data: filteredData,
  };
}

export function useForSaleListingsPaginated(
  params: Omit<PaginatedQueryParams, 'isPublic' | 'listingTypes'> & {
    forSaleItemType: ForSaleItemType;
  },
  options: UseQueryOptions<GetForSaleListingsResponse, ApiError, GetForSaleListingsResponse, PaginatedKey> = {}
) {
  const { collectibleIds, limit, offset, collectibleType, sortBy, sortDirection, forSaleItemType, advancedFilters } =
    params;
  const listingTypes = forSaleItemType === 'Auctions' ? ['Auction'] : ['Store', 'AuctionWithBin'];
  const { isLoggedIn } = useAuth();
  const isPublic = !isLoggedIn;
  const { data, ...otherQueryResults } = useQuery(
    [
      keyPrefix,
      {
        limit,
        offset,
        collectibleIds,
        sortBy,
        sortDirection,
        collectibleType,
        isPublic,
        listingTypes,
        advancedFilters,
      },
    ],
    async ({ signal }) =>
      getForSaleListings(
        {
          limit,
          offset,
          collectibleIds,
          sortBy,
          sortDirection,
          collectibleType,
          isPublic,
          listingTypes,
          advancedFilters,
        },
        signal
      ),
    {
      ...options,
      enabled: (!!isLoggedIn || isPublic) && !!collectibleIds?.length && (options?.enabled ?? true),
    }
  );

  const filteredItems = useFilteredListings(data?.items ?? [], collectibleType, params);

  return {
    ...otherQueryResults,
    data: data
      ? {
          ...data,
          items: filteredItems,
        }
      : null,
  };
}

function useFilteredListings(
  data: ForSaleListingItem[],
  collectibleType: CollectibleType,
  { marketplaces, acceptsOffers, playerName, setName, setYear, searchText }: FilterParams
) {
  // TODO: this should be done in API instead
  const filterByPlatform = useCallback<Filter<ForSaleListingItem>>(
    (item) => !marketplaces?.length || marketplaces.includes(item.platformId),
    [marketplaces]
  );
  const filterByAcceptsOffers = useCallback<Filter<ForSaleListingItem>>(
    (item) => !acceptsOffers || item.bestOfferEnabled,
    [acceptsOffers]
  );
  const filterByPlayerName = useCallback<Filter<ForSaleListingItem>>(
    (item) =>
      !playerName ||
      !isSportsCardCollectibleType(collectibleType) ||
      (item as ForSaleListingItemSportsCard).playerName === playerName,
    [playerName, collectibleType]
  );
  const filterBySetName = useCallback<Filter<ForSaleListingItem>>(
    (item) =>
      !setName ||
      !(isSportsCardCollectibleType(collectibleType) || isSealedWaxCardCollectibleType(collectibleType)) ||
      (item as ForSaleListingItemSportsCard | ForSaleListingItemSealedWax).setName === setName,
    [setName, collectibleType]
  );
  const filterBySetYear = useCallback<Filter<ForSaleListingItem>>(
    (item) =>
      !setYear ||
      !(isSportsCardCollectibleType(collectibleType) || isSealedWaxCardCollectibleType(collectibleType)) ||
      (item as ForSaleListingItemSportsCard | ForSaleListingItemSealedWax).setYear === setYear,
    [setYear, collectibleType]
  );

  return useFilteredData(data, 'title', searchText, [
    filterByPlatform,
    filterByAcceptsOffers,
    filterBySetName,
    filterBySetYear,
    filterByPlayerName,
  ]);
}
