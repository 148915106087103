import React from 'react';
import clsx from 'clsx';
import { faArrowUpRightFromSquare } from '@fortawesome/pro-solid-svg-icons';
import { faCircleDollar, faSquarePlus } from '@fortawesome/pro-light-svg-icons';
import { differenceInDays } from 'date-fns';
import { normalizeListingType } from '@sportscardinvestor/marketplace-helpers';
import { parseSalePlatform } from '@sportscardinvestor/schemas';
import ModalDialog from '../../../sci-ui-components/ModalDialog/ModalDialog';
import { RawCompletedSaleSearchItem } from '../useRawCompletedSalesSearch';
import useCollectibleType from '../../collectibles/useCollectibleType';
import CollectibleText from '../../../sci-ui-components/collectibles/CollectibleText/CollectibleText';
import { formatDate } from '../../../sci-ui-components/utils/date';
import DashboardStat from '../../../sci-ui-components/stats/DashboardStat/DashboardStat';
import { listingTypeNames } from '../../admin/sales/completed/constants';
import ExtraActionsRow from '../../../sci-ui-components/ExtraActionsRow/ExtraActionsRow';
import { ebayAffiliateDisclosure } from '../../../constants';
import Button from '../../../sci-ui-components/buttons/Button/Button';
import CollectibleImageModal from '../../../sci-ui-components/collectibles/CollectibleImageModal/CollectibleImageModal';
import { MarketplaceLogo } from '../../sales/MarketplaceLogo';
import classes from './SaleDetailsDialog.module.scss';
import { marketplaceHelpers } from 'services/marketplaceHelpers';
import { useMakeAddToCollectionInitialValues } from '@/features/collection/AddCollectionItemDialog/useMakeAddToCollectionInitialValues';
import { addCollectionItem } from '@/features/collection/AddCollectionItemDialog';

const EBAY_LISTING_VISIBILITY_THRESHOLD_IN_DAYS = 90;

const SaleDetailsDialog = ({
  isOpen,
  onClose,
  sale,
  query,
}: {
  isOpen: boolean;
  onClose: () => void;
  sale?: RawCompletedSaleSearchItem | null;
  query: string;
}) => {
  const { collectibleType } = useCollectibleType();
  const { makeFromSalesHistoryItem, isLoading: isLoadingCollectionItemInitialInfo } =
    useMakeAddToCollectionInitialValues();

  if (!sale) {
    return null;
  }

  const actions = [
    {
      id: 'onForSale',
      label: `For Sale \n(${ebayAffiliateDisclosure})`,
      externalLink: marketplaceHelpers.makeSearchUrl({ query, marketplace: 'ebay_jobs', activeOnly: true }),
      faIcon: faCircleDollar,
    },
    {
      id: 'onCollectionAdd',
      label: 'Add Custom Collectible to Collection',
      onSelect: async () => {
        const initialValues = await makeFromSalesHistoryItem({
          collectibleType,
          item: sale,
          searchText: query,
        });
        addCollectionItem(initialValues);
      },
      faIcon: faSquarePlus,
    },
  ];

  const isViewListingButtonDisabled =
    sale.platformId === 'ebay_jobs' &&
    differenceInDays(new Date(), new Date(sale.endTime)) > EBAY_LISTING_VISIBILITY_THRESHOLD_IN_DAYS;

  const platformId = parseSalePlatform({ platformName: sale.platformId });

  return (
    <ModalDialog
      open={isOpen}
      onCancel={onClose}
      footer={null}
      dense={true}
      width={600}
      className={classes.modalContainer}
      isLoading={isLoadingCollectionItemInitialInfo}
    >
      <div className={clsx(classes.detailsContainer, 'flex flex-col sm:flex-row gap-2')}>
        <CollectibleImageModal
          className={classes.image}
          collectibleType={collectibleType}
          url={sale.imageUrls[0]}
          alt={sale.displayTitle || 'Sale'}
          size="original" // NOTE: we do not have responsive images for sale images
        />
        <div className={classes.dataContainer}>
          <div className={classes.innerDataContainer}>
            <CollectibleText type={'title'} size={'large'}>
              {sale?.displayTitle}
            </CollectibleText>
            <div className={classes.statRow}>
              <DashboardStat
                className={classes.statBox}
                value={sale.offerPrice || sale.sellingPrice}
                label="Sale Price"
                type="price"
                variant="context"
                size="medium"
              />

              <DashboardStat
                className={classes.statBox}
                value={formatDate(sale.saleDate)}
                label="Sold Date"
                type="custom"
                variant="context"
                size="medium"
              />
            </div>
            <div className={classes.statRow}>
              <DashboardStat
                className={classes.statBox}
                value={<MarketplaceLogo className={classes.logoLabel} platformId={platformId} />}
                label={'Marketplace'}
                type="custom"
                variant="context"
                size="medium"
              />
              <DashboardStat
                className={classes.statBox}
                value={`${listingTypeNames[sale.listingType || 'FixedPrice']}${
                  sale.numberOfBids ? `(${sale.numberOfBids} bids)` : ''
                }`}
                label={'Sales Type'}
                type="custom"
                variant="context"
                size="medium"
              />
            </div>
            {sale.seller.name && sale.platformId === 'ebay_jobs' ? (
              <div className={classes.statRow}>
                <DashboardStat
                  className={classes.statBox}
                  value={`${sale.seller.name}(${sale.seller.feedbackRating})`}
                  label={'Seller (Feedback)'}
                  type="custom"
                  variant="context"
                  size="medium"
                />
              </div>
            ) : null}
          </div>

          <div className={classes.actionsContainer}>
            <ExtraActionsRow actions={actions} size={'medium'} dense linkClassname={classes.actionButton} />
            <Button
              type={'primary'}
              faIcon={faArrowUpRightFromSquare}
              href={marketplaceHelpers.makeDirectItemUrl({
                url: sale.saleUrl,
                marketplace: sale.platformId,
                listingType: normalizeListingType(sale.listingType),
              })}
              target={'_blank'}
              disabled={isViewListingButtonDisabled}
              hoverTooltipText={
                isViewListingButtonDisabled
                  ? "eBay doesn't support direct links for sales beyond 90 days ago"
                  : undefined
              }
            >
              View Listing
            </Button>
          </div>
        </div>
      </div>
    </ModalDialog>
  );
};

export default SaleDetailsDialog;
